<template>
  <div class="case-creation__step case-creation__billing-information">
    <div class="case-creation__card">
      <div class="case-creation__heading edit-view">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-billing-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Billing Information</h3>
        </div>
      </div>
      <div class="case-creation__body">
        <div class="case-creation__tabs billing-information-tabs vue-tabs">
          <el-tabs v-model="activeName">
            <el-tab-pane label="PRIMARY INSURANCE" name="first">
              <div id="primary-insurance" v-if="activeName === 'first'">
                <div class="primary-insurance-view">
                  <div class="step-inner-navbar"></div>

                  <div class="step-inner-content">
                    <div class="vue-form">
                      <el-form>
                        <el-row :gutter="15">
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Relationship</h4>
                              <p class="value" v-if="getBillingInformation">
                                {{ getBillingInformation.relationship }}
                              </p>
                              <p class="value" v-else>--</p>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Plan Type</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.plan_type
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">First Name</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.first_name
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Middle Name</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.middle_name
                                }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Last Name</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.last_name
                                }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Gender</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.gender
                                }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Date of Birth</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  formatDate(
                                    getSingleEMROrder.billing_info.insurance
                                      .primary_insurance.date_of_birth
                                  )
                                }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Name</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.payor
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Carrier Code</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.insurance_carrier_code
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Policy No</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.policy_id
                                }}
                              </span>
                              <span class="value" v-else> --</span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group#</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.group
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 1</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.address_line_1
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 2</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.address_line_2
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Zip</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.zip
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">State</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.state
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">City</h4>
                              <span class="value" v-if="getBillingInformation">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .primary_insurance.city
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="SECONDARY INSURANCE" name="second">
              <div id="secondary-insurance" v-if="activeName === 'second'">
                <div class="secondary-insurance-view">
                  <div class="step-inner-navbar"></div>

                  <div class="step-inner-content">
                    <div class="vue-form">
                      <el-form>
                        <el-row :gutter="15">
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Relationship</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.relationship
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Plan Type</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.plan_type
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">First Name</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.first_name
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Middle Name</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.middle_name
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Last Name</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.last_name
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Gender</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.gender
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Date of Birth</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  formatDate(
                                    getSingleEMROrder.billing_info.insurance
                                      .secondary_insurance.date_of_birth
                                  )
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Name</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.payor
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Carrier Code</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.insurance_carrier_code
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Policy No</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.policy_id
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group#</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.group
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 1</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.address_line_1
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 2</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.address_line_2
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Zip</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.zip
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">State</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.state
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">City</h4>
                              <span class="value" v-if="getSecondaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .secondary_insurance.city
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="TERTIARY INSURANCE" name="third">
              <div id="tertiary-insurance" v-if="activeName === 'third'">
                <div class="tertiary-insurance-view">
                  <div class="step-inner-navbar"></div>

                  <div class="step-inner-content">
                    <div class="vue-form">
                      <el-form>
                        <el-row :gutter="15">
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Relationship</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.relationship
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Plan Type</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.plan_type
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">First Name</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.first_name
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Middle Name</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.middle_name
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Last Name</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.last_name
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Gender</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.gender
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Date of Birth</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  formatDate(
                                    getSingleEMROrder.billing_info.insurance
                                      .tertiary_insurance.date_of_birth
                                  )
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Name</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.payor
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Insurance Carrier Code</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.insurance_carrier_code
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Policy No</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.policy_id
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Group#</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.group
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 1</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.address_line_1
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Address Line 2</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.address_line_2
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">Zip</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.zip
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>

                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">State</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.state
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                            <div
                              class="case-creation__input input-value view-cases"
                            >
                              <h4 class="name">City</h4>
                              <span class="value" v-if="getTertiaryInsurance">
                                {{
                                  getSingleEMROrder.billing_info.insurance
                                    .tertiary_insurance.city
                                }}
                              </span>
                              <span class="value" v-else> -- </span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="GUARANTOR INSURANCE" name="fourth">
              <div id="guarantorForm-view" v-if="activeName === 'fourth'">
                <div class="step-inner-navbar"></div>

                <div class="step-inner-content">
                  <div class="vue-form">
                    <el-form>
                      <el-row :gutter="15">
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Relationship</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.relationship
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">First Name</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.first_name
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Middle Name</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.middle_name
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Last Name</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.last_name
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Gender</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.gender
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Address Line 1</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.address_line_1
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Address Line 2</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.address_line_2
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">Zip</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.zip
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">State</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.state
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                        <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                          <div
                            class="case-creation__input input-value view-cases"
                          >
                            <h4 class="name">City</h4>
                            <span class="value" v-if="getGuarantor">
                              {{
                                getSingleEMROrder.billing_info.insurance
                                  .guarantor.city
                              }}
                            </span>
                            <span class="value" v-else> -- </span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
  <div class="default-modal">
    <div class="archive-popup">
      <el-dialog
        top="25vh"
        v-model="insurancePopup"
        v-loading="showInsuranceLoading"
      >
        <div class="dialog-header">
          <span class="header-part">
            {{ insuranceEligibilityDetails.foundCoverages[0].payerName }} -
            {{ insuranceEligibilityDetails.foundCoverages[0].payerCode }}
          </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="insurancePopup = false"
          >
            <img src="@/assets/images/close-icon.svg" alt="icon"
          /></el-button>
        </div>

        <div
          class="popup-date pt-0"
          v-if="
            insuranceEligibilityDetails.foundCoverages[0].coverageStatus == 1
          "
        >
          <div class="summary">
            <h4 class="title">Summary :</h4>
            <ul>
              <li>
                Group Name :
                <span>
                  {{
                    insuranceEligibilityDetails.foundCoverages[0].summary
                      .groupName
                  }}
                </span>
              </li>
              <li>
                Group Number :
                <span>
                  {{
                    insuranceEligibilityDetails.foundCoverages[0].summary
                      .groupNumber
                  }}
                </span>
              </li>
              <li>
                Name :
                <span>
                  {{
                    insuranceEligibilityDetails.foundCoverages[0].summary.name
                  }}
                </span>
              </li>
              <li>
                Plan Type :
                <span>
                  {{
                    insuranceEligibilityDetails.foundCoverages[0].summary
                      .planType
                  }}
                </span>
              </li>
              <li>
                Policy Start :
                <span>
                  {{
                    formatDate(
                      insuranceEligibilityDetails.foundCoverages[0].summary
                        .policyStart
                    )
                  }}
                </span>
              </li>
              <li>
                Policy End :
                <span>
                  {{
                    formatDate(
                      insuranceEligibilityDetails.foundCoverages[0].summary
                        .policyEnd
                    )
                  }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="popup-date pt-0" v-else>
          <div class="summary">
            <ul>
              <li>
                Additional Details :
                <span>
                  {{
                    insuranceEligibilityDetails.foundCoverages[0]
                      .additionalDetail
                  }}
                </span>
              </li>
              <li>
                Rejected Reason :
                <span>
                  {{
                    insuranceEligibilityDetails.foundCoverages[0]
                      .rejectionReason
                  }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeName: "first",
      loading: false,
    };
  },
  computed: {
    ...mapGetters("emrOrders", ["getSingleEMROrder"]),
    getBillingInformation() {
      if (
        this.getSingleEMROrder &&
        Object.keys(this.getSingleEMROrder).length > 0 &&
        this.getSingleEMROrder.billing_info &&
        this.getSingleEMROrder.billing_info.insurance
      ) {
        return this.getSingleEMROrder.billing_info.insurance.primary_insurance;
      }
      return null;
    },
    getSecondaryInsurance() {
      if (
        this.getSingleEMROrder &&
        Object.keys(this.getSingleEMROrder).length > 0 &&
        this.getSingleEMROrder.billing_info &&
        this.getSingleEMROrder.billing_info.insurance
      ) {
        return this.getSingleEMROrder.billing_info.insurance
          .secondary_insurance;
      }
      return null;
    },
    getTertiaryInsurance() {
      if (
        this.getSingleEMROrder &&
        Object.keys(this.getSingleEMROrder).length > 0 &&
        this.getSingleEMROrder.billing_info &&
        this.getSingleEMROrder.billing_info.insurance
      ) {
        return this.getSingleEMROrder.billing_info.insurance.tertiary_insurance;
      }
      return null;
    },
    getGuarantor() {
      if (
        this.getSingleEMROrder &&
        Object.keys(this.getSingleEMROrder).length > 0 &&
        this.getSingleEMROrder.billing_info &&
        this.getSingleEMROrder.billing_info.insurance
      ) {
        return this.getSingleEMROrder.billing_info.insurance.guarantor;
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.case-creation__billing-information {
  .case-creation__body {
    padding: 0;
  }
  .el-tabs__content {
    padding: 0 20px;
  }
  .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  .el-tabs__nav-wrap::after {
    height: 1px;
  }
  .right-align {
    display: flex;
  }
  .eligibility-block {
    width: 60%;
    display: flex;
    align-items: center;
    .verified {
      color: #4bb543;
      border: 0.6px solid #4bb543;
      background-color: #ffffff;
      padding: 1px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-weight: 500;
      border-radius: 5px;
      min-height: 25px;
      cursor: pointer;
      .el-icon {
        padding-left: 3px;
      }
    }
    .not-verified {
      color: #f24a55;
      border: 0.6px solid #f24a55;
      background-color: #ffffff;
      padding: 1px 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      border-radius: 5px;
      min-height: 25px;
      cursor: pointer;
      .el-icon {
        padding-left: 3px;
      }
    }

    .date-time {
      padding: 0 15px;
      font-size: 14px;
      font-weight: 500;
    }
    .eligibility-btn {
      margin-left: 20px;
      background: #f24a55;
      min-height: 25px;
      border: none;
      padding: 5px 20px;
      color: #fff;
      &:hover,
      &:active {
        color: #fff;
        background: #f24a55;
      }
      &.check {
        background: #273480;
        color: #ffffff;
      }
    }
  }
  .delete-billing-info {
    margin-right: 20px;
    padding: 5px 0;

    button {
      background: #f24a55;
      min-height: 25px;
      border: none;

      padding: 5px 20px;
    }
  }
}
</style>
